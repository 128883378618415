/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "ContentfulEventWithID",
            "endpoint": "https://m728606i2f.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "ContentfulEvents",
            "endpoint": "https://hx6i87ghhh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "ContentfulEvents2",
            "endpoint": "https://h7oyldr60g.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
